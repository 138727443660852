<template>
    <div class="mt-3">
        <button class="btn btn-first searchform-type" @click="searchByChange('name')">Назва</button>
        <button class="btn btn-first searchform-type" @click="searchByChange('code')">Штрих-код</button>
        <button class="btn btn-first searchform-type" @click="searchByChange('recipe')">Рецепт</button>
        <div class="row" v-if="searchBy === 'name'">
            <SearchByName />
        </div>
        <div class="row" v-if="searchBy === 'code'">
            <SearchByCode />
        </div>
        <div class="row" v-if="searchBy === 'recipe'">
            <SearchByRecipe />
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useAuthStore } from '../store/auth'
    import { useProdsStore } from '../store/prods'
    import SearchByName from '../components/SearchByName.vue';
    import SearchByCode from '../components/SearchByCode.vue';
    import SearchByRecipe from '../components/SearchByRecipe.vue';

    const auth = useAuthStore()
    const prods = useProdsStore()

    const name = ref('')
    const searchBy = ref('')

    onMounted(() => {
        console.log("SearchForm onMount")
    })

    function searchByChange(s) {
        searchBy.value = s;
    }
</script>

<style>
</style>
