import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import { APISettings } from '../config/api.js';
import { useAuthStore } from './auth';
import { useRroStore } from './rro';
import { useApiStore } from "./api.js";

export const useClientsStore = defineStore({
    id: 'clients',

    state: () => ({
        clients: useStorage('clients_clients', []),
        client: useStorage('clients_client', {}),
    }),

    actions: {
        async sync() {
            this.fetchClients();
        },

        async fetchClients() {
            const api = useApiStore();
            const data = await api.get('/clients');            
            this.clients = data;
        },

        fetchClient(name) {
            var results = Array();
            
            for (let i = 0; i < this.clients.length; i++) {
                let client = this.clients[i];

                if (client.phone.includes(name) || client.code === name) {
                    this.client = client;
                }
            }
            return this.client;
        }
    }
})