<template>
    <div>
        <h3>Сплатіть замовлення</h3>
        <hr>
        <div class="row">
            <div class="col-6">
                <button class="form-control btn btn-first" v-on:click="showCheckBlock('cash')">Готівка</button>
            </div>
            <div class="col-6">
                <button class="form-control btn btn-first" v-on:click="showCheckBlock('liqpay')">Liqpay</button>
            </div>
            <div class="col-6">
                <button class="form-control btn btn-first" v-on:click="showCheckBlock('terminal')">Термінал</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useOrderStore } from '../store/order';

    const order = useOrderStore()

    onMounted(() => {

    })

    function showCheckBlock(type) {        
        order.beginPay(type)
    }
</script>

<style>
</style>
