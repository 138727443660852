<template>    
    <div v-if="rro.isShiftOpened">
        <div v-if="!order.order_completed">
            <div class="card mb-3 sale-search-block" v-if="order.show_search_block">
                <div class="card-body">
                    <h3>Продаж</h3>
                    <hr>
                    <SearchForm />
                </div>
            </div>
            <div class="card mb-3" v-if="prods.prods.length">
                <div class="card-body">
                    <ProdSelect />
                </div>
            </div>
            <template v-if="order.prods.length">
                <Cart />
            </template>
            <div class="card mb-3" v-if="order.show_payment_block">
                <div class="card-body">
                    <Pay />
                </div>
            </div>
            <div class="card mb-3" v-if="order.show_check_block">
                <div class="card-body">
                    <Check />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card mb-3">
                <div class="card-body">
                    <CheckCont :check="order.check" :prods="order.prods" />
                    <hr>
                    <a href="#" class="form-control btn btn-first" v-on:click="clearCheck()">На головну</a>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <OpenShiftBlock />
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { RouterView, useRouter } from 'vue-router'
    import SearchForm from '../components/SearchForm.vue'
    import Cart from '../components/Cart.vue';
    import CheckItem from '../components/CheckItem.vue';
    import OpenShiftBlock from '../components/OpenShiftBlock.vue';
    import ProdSelect from '../components/ProdSelect.vue';
    import Pay from '../components/Pay.vue';
    import Check from '../components/Check.vue';
    import CheckCont from '../components/CheckCont.vue';

    import { useRroStore } from '../store/rro';
    import { useOrderStore } from '../store/order';
    import { useProdsStore } from "../store/prods";

    const router = useRouter();
    const rro = useRroStore();
    const order = useOrderStore();
    const prods = useProdsStore();
        
    onMounted(() => {
        console.log('Sale mounted...')
        order.show_payment_block = false;
    })

    function clearCheck() {
        order.clearOrder();
        router.push({path: '/'});
    }
</script>

<style>
</style>
