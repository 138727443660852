import { defineStore } from "pinia";
import { useApiStore } from "./api.js";

export const useRecipeStore = defineStore({
    id: 'recipe',

    state: () => ({
        recipe_number: '',
        recipe: null,
    }),

    actions: {        
        async fetchRecipe(number) {
            let api = useApiStore();
            const data = await api.get('/recipe/' + number);
            
            console.log(data);
            this.recipe = data;
            this.recipe_number = number;        
        },
        
        async confirmRecipe() {
            let api = useApiStore();
            const data = await api.get('/recipe/confirm/' + number);

            if (data.id) {
                this.recipe = null;
                this.recipe_number = '';
            }
        }
    }
})