<template>
    <div v-if="rro.isShiftOpened">
        <div class="card">
            <div class="card-body">
                <h3>X-Звіт</h3>
                <hr>
                <div v-if="xreport">
                    <h2>{{ xreport.rro.name }}</h2>
                    <br>
                    {{ xreport.rro.addr }}
                    <br><br>
                    <h3>Реалізація</h3>

                    Кількість чеків ... {{ xreport.real.count }} <br>
                    Готівка ... {{ xreport.real.payform.cash }} <br>
                    Безготівка ... {{ xreport.real.payform.terminal1 }} <br>
                    Безготівка 1 ... {{ xreport.real.payform.terminal2 }} <br>
                    Безготівка 2 ... {{ xreport.real.payform.terminal3 }} <br>
                    Безготівка 3 ... {{ xreport.real.payform.terminal4 }} <br>
                    ОБІГ ПДВ А 20% ... {{ xreport.real.tax20.turnover }} <br>
                    ОБІГ ПДВ Б 7% ... {{ xreport.real.tax7.turnover }} <br>
                    ОБІГ ПДВ В 0% ... {{ xreport.real.tax0.turnover }} <br>
                    ПДВ A 20% ... {{ xreport.real.tax20.tax }} <br>
                    ПДВ Б 7% ... {{ xreport.real.tax7.tax }} <br>
                    ПДВ В 0% ... {{ xreport.real.tax0.tax }} <br>
                    Загальна сума податків ... {{ xreport.real.taxes }} <br>
                    Загальна сума ... {{ xreport.real.amount }} <br>
                    <br>
                    <h3>Повернення</h3>

                    Кількість чеків ... {{ xreport.ret.count }} <br>
                    Готівка ... {{ xreport.ret.payform.cash }} <br>
                    Безготівка ... {{ xreport.ret.payform.terminal1 }} <br>
                    Безготівка 1 ... {{ xreport.ret.payform.terminal2 }} <br>
                    Безготівка 2 ... {{ xreport.ret.payform.terminal3 }} <br>
                    Безготівка 3 ... {{ xreport.ret.payform.terminal4 }} <br>
                    ОБІГ ПДВ А 20% ... {{ xreport.ret.tax20.turnover }} <br>
                    ОБІГ ПДВ Б 7% ... {{ xreport.ret.tax7.turnover }} <br>
                    ОБІГ ПДВ В 0% ... {{ xreport.ret.tax0.turnover }} <br>
                    ПДВ A 20% ... {{ xreport.ret.tax20.tax }} <br>
                    ПДВ Б 7% ... {{ xreport.ret.tax7.tax }} <br>
                    ПДВ В 0% ... {{ xreport.ret.tax0.tax }} <br>
                    Загальна сума податків ... {{ xreport.ret.taxes }} <br>
                    Загальна сума ... {{ xreport.ret.amount }} <br>

                    <br>

                    {{ xreport.date }}

                    <br><br>
                    ФН ППРО ... {{ xreport.rro.fn }} <br>
                </div>
                <div v-else>
                    <form>
                        <button type="button" class="form-control btn btn-first mb-3" v-on:click="xReport">X-Звіт</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <OpenShiftBlock />
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';
    import OpenShiftBlock from '../components/OpenShiftBlock.vue';

    const auth = useAuthStore()
    const rro = useRroStore()
    const xreport = ref(null)

    function xReport() {
        xreport.value = rro.xReport()
    }

    onMounted(() => {
        console.log('XReport mounted...')
    })
</script>

<style>

</style>
