<template>
    <div class="card mb-3">
        <div class="card-body">
            <h3>Зміна закрита</h3>
            <hr>
            <RouterLink to="/openshift" class="form-control btn btn-first">Відкрити зміну</RouterLink>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
</style>
