<template>
    <div class="card mb-3" v-if="!order.show_payment_block && !order.show_check_block">
        <div class="card-body">
            <button class="btn btn-first btn-add-prods" v-on:click="showSearchBlock()">Додати</button>
            <h3>Обрані товари</h3>
            <CartItem v-for="(prod, key) in order.prods" :prod_key="key" :prod="prod" />
        </div>
    </div>
    <div class="card mb-3" v-if="!order.show_payment_block && !order.show_check_block">
        <div class="card-body">
            <button class="form-control btn btn-first button-back btn-hide-payment" v-on:click="showPaymentBlock()">Оформити</button>
        </div>
    </div>
    <div class="card mb-3" v-if="order.show_payment_block">
        <div class="card-body">
            <button class="btn btn-first button-back btn-hide-payment" v-on:click="hidePaymentBlock()"><span class="material-symbols-rounded">arrow_back</span></button>
            <h3>Оформлення</h3>
        </div>
    </div>
    <div class="card mb-3" v-if="order.show_payment_block">
        <div class="card-body">
            <Client />
            <hr>
            <h3>Загальна сума: {{ order.sum }} грн.</h3>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth'
    import { useOrderStore } from '../store/order';

    import CartItem from './CartItem.vue';
    import Client from './Client.vue';

    const auth = useAuthStore()
    const order = useOrderStore()

    const prods_num = ref([])

    const props = defineProps({
        type: {
            type: String,
            default: 'sale'
        }
    });

    onMounted(() => {
        order.type = props.type;
        order.show_search_block = false;
    })

    function showSearchBlock() {
        order.show_search_block = true;
    }

    function showPaymentBlock() {
        order.show_payment_block = true;
        order.show_search_block = false;
    }

    function hidePaymentBlock() {
        order.show_payment_block = false;
    }
</script>

<style>
    .btn-add-prods, .btn-hide-payment {
        float: right;
    }
</style>
