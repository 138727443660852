import { defineStore } from "pinia";
import { useStorage } from '@vueuse/core';
import { useApiStore } from "./api.js";
import { useAuthStore } from "./auth.js";
import { APISettings } from '@/config/api';
import { useOrderStore } from "./order.js";
export const useProdsStore = defineStore({
    id: 'prods',

    state: () => ({
        store: useStorage('prods_store', []),
        prods: [],
        prod: null
    }),

    getters: {
        getProds: (state) => {      
            return state.prods
        }
    },

    actions: {
        async sync () {
            this.syncStore();
        },

        async syncStore() {
            const api = useApiStore();
            const auth = useAuthStore();
            console.log(auth.token);

            // const response = await fetch(APISettings.baseURL + '/supplies', {
            //     method: 'GET',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + auth.token,
            //         'cache': 'no-cache',
            //     },                
            // });

            // if (response.status == 401) {
            //     console.log('auth error')
            //     auth.logout();
            // }
            
            // if (response.status == 200) {               
            //     var data = await response.json();
            // } else {                
            //     //return null;
            // }
            const data = await api.get('/supplies');

            console.log('syncStore...', typeof(data), data);

            for (var i = 0; i < data.length; i++) {
                console.log('syncStore'+i+'...', typeof(data[i]));
                var prod = data[i];
                data[i].vendor = prod.prod.vendor;
                data[i].daysleft = prod.prod.daysleft;
                data[i].form = prod.prod.form;
                data[i].inpack = prod.prod.inpack;
                data[i].recipe = prod.prod.recipe;
                data[i].name = prod.prod.name;
                data[i].analog = prod.prod.analog;
                data[i].excise = prod.prod.excise;
                data[i].kw = prod.prod.kw;
            }

            this.store = data;
        },

        fetchProdsByName(name) {
            var n = name.toLowerCase();
            this.prods = [];
            for (let i = 0; i < this.store.length; i++) {
                let prod = this.store[i];
                let prod_name = prod.name.toLowerCase();
                let prod_kw = prod.kw.toLowerCase();
                if (prod_name.includes(n) || prod_kw.includes(n)) {
                    this.prods.push(prod);
                }
            }

            this.prod = null;
        },

        fetchProdsByCode(code) {
            var results = Array();
            
            for (let i = 0; i < this.store.length; i++) {
                let prod = this.store[i];
                let prod_code = prod.prod.name.toLowerCase();
                
                if (prod_code === code) {
                    results.push(prod);
                }
            }
            this.prods = results;
            this.prod = null;
        },

        selectProd(prod) {
            this.prod = prod;
        },

        addProdToCheck(prod) {
            const order = useOrderStore();

            order.prods.push(prod);
            this.prod = null;
            this.prods = [];       
        }
    }
})