<template>
    <div class="col mt-3">
        <div class="row">
            <template v-if="recipe.recipe === null">
                <div class="col-12">
                    <h5>Погашення рецепту</h5>
                    <hr>
                </div>
                <div class="col-12 col-sm-9 mb-3">
                    <input type="text" class="form-control" v-model="number" v-on:keypress="getRecipeInfo()">
                </div>
                <div class="col-12 col-sm-3 mb-3">
                    <button type="button" class="form-control btn btn-second" v-on:click="getRecipeInfo()">Знайти</button>
                </div>
            </template>

            <template v-else>
                <div class="col-12">
                    <h5>Рецепт номер: {{ recipe.recipe.number }}</h5>
                </div>
                <div class="col-6">
                    <!-- TODO  Вывести всю информацию о рецепте, как в видео. Поля посмотри тут /api -->
                    <button type="button" class="form-control btn btn-second" v-on:click="getRecipeProd()">Знайти товари</button>
                </div>
                <div class="col-6">
                    <button type="button" class="form-control btn btn-first" v-on:click="confirmRecipe()">Погасити</button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { APISettings } from '../config/api'
    import { ref, reactive, onMounted } from 'vue'
    import { useProdsStore } from '../store/prods'
    import { useRecipeStore} from '../store/recipe'

    const recipe = useRecipeStore();
    const prods = useProdsStore();

    const number = ref('')
    const name = ref('')

    onMounted(() => {
        console.log('Sale mounted...')
    })

    function getRecipeInfo() {
        recipe.fetchRecipe(number.value);
    }

    function getRecipeProd() {
        name.value = recipe.recipe.prod;
        fetchProds();
    }

    function confirmRecipe() {
        recipe.confirmRecipe();
    }

    function fetchProds() {
        prods.fetchProdsByName(name.value);
    }
</script>

<style>
</style>
