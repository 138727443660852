<template>
    <div class="card">
        <div class="card-body">
            <RouterLink to="/sale" class="form-control btn btn-first">Нова Продаж</RouterLink>
            <hr>
            <CheckList />
        </div>
    </div>
</template>

<script setup>
    import CheckList from '../components/CheckList.vue';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';

    const auth = useAuthStore()
    const rro = useRroStore()
</script>

<style>
</style>
