<template>
    <div class="card">
        <div class="card-body">
            <SearchByRecipe />
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import SearchByRecipe from '../components/SearchByRecipe.vue';

    onMounted(() => {
        console.log('RecipePage mounted...')
    })
</script>

<style>
</style>
