<template>
    <div>
        <div class="row">
            <div v-if="order.client.id">
                <h3>{{ order.client.name }}. Знижка: {{ order.client.discount }}%</h3>
                <hr>
                <button type="button" class="form-control btn btn-first" v-on:click="clearClient()">Інший клієнт</button>
            </div>
            <div v-else>
                <h3>Якщо є дисконтна картка, введіть її номер</h3>
                <hr>
                <form class="row">
                    <div class="col-6">
                        <input type="text" class="form-control" id="cardnumber" placeholder="" v-model="code">
                    </div>
                    <div class="col-6">
                        <button type="button" class="form-control btn btn-first" v-on:click="fetchClient">Знайти</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useAuthStore } from '../store/auth'
    import { useOrderStore } from '../store/order';

    const auth = useAuthStore()
    const order = useOrderStore()

    const code = ref('')

    onMounted(() => {
    })

    function clearClient() {
        order.client = {};
    }

    function fetchClient() {
        order.fetchClient(code.value);
    }
</script>

<style>
</style>
