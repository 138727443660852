<template>
    <div class="card">
        Terminal 
        <Terminal />
        
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import Terminal from '@/components/Pay/Terminal.vue';

    onMounted(() => {
        console.log('Input mounted...')
    })
</script>

<style>
</style>
