<template>
    <hr>
    <div class="row pay-primary">
        <div class="col-6">Загальна сума: </div>
        <div class="col-6">{{ order.sum }} грн.</div>
    </div>
    <hr>
    <div class="row pay-primary">
        <div class="col-6">Отримано від клієнта: </div>
        <div class="col-6"><input type="text" class="form-control" @input="cashEnter($event)"></div>
    </div>
    <hr>
    <div class="row pay-primary">
        <div class="col-6">Решта: </div>
        <div class="col-6">{{ rest }} грн.</div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <button class="form-control btn btn-first" @click="pay()">Сплачено</button>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useOrderStore } from '../../store/order';
    import { useClientsStore } from '@/store/clients';

    const order = useOrderStore()
    const prods = useOrderStore()
    const clients = useClientsStore()

    const cash = ref('')
    const rest = ref(-order.sum)

    onMounted(() => {
    })

    function pay() {
        order.orderPayed(order.sum, order.prods, clients.client);
    }

    function cashEnter(event) {
        let value = parseInt(event.target.value);
        if(isNaN(value)) {
            value = 0;
        }
        rest.value = (value - order.sum).toFixed(2);
    }
</script>

<style>
</style>
