<template>    
    <hr>
    <div class="prodbuy">        
        <div class="row">
            <div class="col-12">
                <div class="prodvar-name">{{ props.prod.name }}<template v-if="props.prod.form"> ({{ props.prod.form }})</template></div>
            </div>
            <div class="col-6">
                <div class="prodvar-vendor" v-if="props.prod.vendor">{{ props.prod.vendor.name }}, {{ props.prod.vendor.country }}</div>
                <div class="prodvar-code" v-if="props.prod.code">Код: {{ props.prod.code }}</div>
            </div>
            <div class="col-4">
                <div class="prodvar-num">
                    {{ props.prod.cart_num }}
                    <span v-if="props.prod.cart_izm === 'packs'">уп.</span>
                    <span v-if="props.prod.cart_izm === 'pieces'">од.</span>
                </div>
                <div class="prodvar-price">{{ props.prod.cart_price * props.prod.cart_num }} грн.</div>
            </div>
            <div class="col-2 prodvar-more" v-if="!order.show_check_block">
                <button class="btn btn-first button-forward" v-on:click="deleteItem()"><span class="material-symbols-rounded">close</span></button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useOrderStore } from '../store/order';

    const order = useOrderStore();

    const num = ref(1);
    const izm = ref('packs');
    const price = ref(0);
    const sum = ref(0);

    const props = defineProps({
        prod_key: Number,
        prod: Object        
    });

    onMounted(() => {
        
    });

    function deleteItem()
    {
        order.deleteItem(props.prod_key)        
    }
</script>

<style>
</style>
