<template>
    <div class="col mt-3">
        <h5>Пошук товара за назвою</h5>
        <hr>
        <div class="row">
            <div class="col-12 col-sm-9 mb-3">
                <input type="text" class="form-control" id="prodname" placeholder="Ібупрофен" v-model="name" v-on:keypress="fetchProds()">
            </div>
            <div class="col-12 col-sm-3 mb-3">
                <button type="button" class="form-control btn btn-second" v-on:click="fetchProds()">Знайти</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useAuthStore } from '../store/auth'
    import { useProdsStore } from '../store/prods'
    import { APISettings } from '../config/api';

    const auth = useAuthStore()
    const prods = useProdsStore()

    const name = ref('')

    function fetchProds() {
        prods.fetchProdsByName(name.value);
    }
</script>

<style>
</style>
