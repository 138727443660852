import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../pages/HomeView.vue'
import AuthView from '../pages/AuthView.vue'
import SaleView from '../pages/SaleView.vue'
import ReturnView from '../pages/ReturnView.vue'
import OpenShiftView from '../pages/OpenShiftView.vue'
import ZReportView from '../pages/ZReportView.vue'
import XReportView from '../pages/XReportView.vue'
import InputView from '../pages/InputView.vue'
import OutputView from '../pages/OutputView.vue'
import RecipeView from '../pages/RecipeView.vue'
import TestView from '../pages/TestView.vue'
import { useAuthStore } from '../store/auth';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/auth',
            name: 'auth',
            component: AuthView
        },
        {
            path: '/sale',
            name: 'sale',
            component: SaleView
        },
        {
            path: '/return',
            name: 'return',
            component: ReturnView
        },
        {
            path: '/openshift',
            name: 'openshift',
            component: OpenShiftView
        },
        {
            path: '/zreport',
            name: 'zreport',
            component: ZReportView
        },
        {
            path: '/xreport',
            name: 'xreport',
            component: XReportView
        },
        {
            path: '/input',
            name: 'input',
            component: InputView
        },
        {
            path: '/output',
            name: 'output',
            component: OutputView
        },
        {
            path: '/recipe',
            name: 'recipe',
            component: RecipeView
        },
        {
            path: '/test',
            name: 'test',
            component: TestView
        }
    ]
})

router.beforeEach(async (to) => {
    const publicPages = ['/auth', '/test'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.isAuth) {
        return '/auth';
    }

    let plugin = document.createElement("script");
    plugin.setAttribute(
        "src",
        "/js/plugins.js"
    );
    plugin.async = true;
    document.querySelector('#dynscripts').innerHTML = '';
    //document.querySelector('#dynscripts').appendChild(plugin);
    $('body').removeClass('page-sidebar-in');
});

export default router
