<template>
    <div v-if="order.show_check_block">
        <button class="btn btn-first button-back" v-on:click="hideCheckBlock()"><span class="material-symbols-rounded">arrow_back</span></button>
        <h3>Закрити чек</h3>
        <CartItem v-for="(prod, key) in order.prods" :prod_key="key" :prod="prod" />
        <hr>
        <div class="row">
            <div class="col-6">Позицій: </div>
            <div class="col-6">{{ order.prods.length }}</div>
        </div>
        <div v-if="order.esystem === 'cash'">
            <Cash />
        </div>
        <div v-if="order.esystem === 'liqpay'">
            <Liqpay />
        </div>
        <div v-if="order.esystem === 'terminal'">
            <Terminal />
        </div>
    </div>

    <div v-if="order.order_completed || order.order_failed">
        <h5>{{ payedResult }}</h5>
    </div>
    <!-- <div class="modal" id="payedModal" tabindex="-1" role="dialog" aria-labelledby="payedModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>{{ payedResult }}</h5>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-first" v-on:click="closeModal()">Ok</button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth'
    import { useOrderStore } from '../store/order';
    import { useChecksStore } from '../store/checks';

    import Cash from './Pay/Cash.vue';
    import Liqpay from './Pay/Liqpay.vue';
    import Terminal from './Pay/Terminal.vue';;    
    import CartItem from './CartItem.vue';

    const order = useOrderStore()
    const checks = useChecksStore();

    const esystem = ref('')
    const payedResult = ref('')

    onMounted(() => {

    })

    function hideCheckBlock(type) {
        order.esystem = '';
        order.show_check_block = false;
        order.show_payment_block = true;
        order.show_search_block = false;
    }

    function closeModal() {
        //$('#payedModal').modal('hide');
        if (payedResult.value == 'Продаж успішна') {
            order.check.ready = true;
            order.check.date = new Date();
            order.check.sum = order.sum;
        }
    }
</script>

<style>
</style>
