<template>
    <div class="card">
        <div class="card-body">
            <h3>Відкріття зміни</h3>
            <hr>
            <div class="row">
                <div v-if="rro.isShiftOpened">
                    Зміна відкріта
                    <hr>
                    <RouterLink to="/" class="form-control btn btn-first">На головну</RouterLink>
                </div>

                <div v-else>
                    Зміна закрита
                    <hr>
                    <form v-if="!rro.isShiftOpened">
                        <button type="submit" class="form-control btn btn-first" v-on:click="openShift">Відкрити зміну</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';

    const auth = useAuthStore()
    const rro = useRroStore()

    function openShift() {
        rro.openShift();
    }

    onMounted(() => {
        console.log('OpenShift mounted...')
    })
</script>

<style>

</style>
