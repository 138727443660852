import { defineStore } from "pinia";
import { APISettings } from '../config/api.js';
import { useStorage } from '@vueuse/core';
import { useApiStore } from "./api.js";

export const useOfflineIdsStore = defineStore('offlineids', {
    state: () => ({
        ids: useStorage('offline_ids', []),
        used_ids: useStorage('offline_usedids', [])
    }),    

    actions: {
        async sync() {
            console.log('Offline Ids sync...');
            this.fetchIds();
        },

        async fetchIds() {
            const api = useApiStore();
            const data = await api.get('/offline_ids');

            console.log('oids...', data);        
            for (var i = 0; i < data.length; i++) {
                var id = data[i].fn;
                if (this.ids.indexOf(id) === -1) {
                    this.ids.push(id);
                }
            }            
        },

        getId() {
            var id = '';

            for (var i = 0; i < this.ids.length; i++) {
                id = this.ids[i];
                if (this.used_ids.indexOf(id) === -1) {
                    this.used_ids.push(id);
                    return id;
                }
            }
            return id;
        },
    }
})