<template>
    <footer class="py-3 pb-lg-5 px-3 px-lg-6">
        <div class="container-fluid px-0">
        <span class="d-block lh-sm small text-muted text-end">&copy;
          . Dorado
        </span>
        </div>
    </footer>
</template>

<script setup>
</script>

<style scoped>
</style>
