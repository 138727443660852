import './css/bootstrap-icons.css'
import './css/style.min.css'
import './css/custom.css'

import $ from 'jquery';
global.$ = global.jQuery = $;

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { bootstrap } from 'bootstrap';

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')