<template>
    <div v-if="rro.isShiftOpened">
        <div v-if="!order.check.ready">
            <div class="card mb-3">
                <div class="card-body">
                    <h3>Повернення</h3>
                    <hr>
                    <SearchForm />
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <ProdSelect />
                </div>
            </div>
            <Cart type="return" />
            <div class="card mb-3">
                <div class="card-body">
                    <button class="form-control btn btn-first" v-on:click="confirmOrder()">Підтвердити повернення</button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card mb-3">
                <div class="card-body">
                    <h3>Чек</h3>
                    <hr>
                    {{ order.check.date }}
                    <hr>
                    <h3>Сума: {{ order.check.sum }} грн.</h3>
                    <hr>
                    <RouterLink to="/" class="form-control btn btn-first">На головну</RouterLink>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <OpenShiftBlock />
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import SearchForm from '../components/SearchForm.vue'
    import Cart from '../components/Cart.vue';
    import OpenShiftBlock from '../components/OpenShiftBlock.vue';
    import ProdSelect from '../components/ProdSelect.vue';

    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';
    import { useOrderStore } from '../store/order';
    import { useChecksStore } from '../store/checks';

    const auth = useAuthStore();
    const rro = useRroStore();
    const order = useOrderStore();
    const checks = useChecksStore();

    onMounted(() => {
        console.log('Sale mounted...')
    })

    function confirmOrder () {
        if (checks.saleReturn(order.prods, order.esystem, order.client)) {
            alert ('Продаж успішна');

            order.check.ready = true;
            order.check.date = new Date();
            order.check.sum = order.sum;

            order.prods = [];
            order.client = {};
            order.discount = 0;
        } else {
            alert ('Помилка! Спробуйте ще раз!');
        }
    }
</script>

<style>
</style>
