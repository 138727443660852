<template>
    <div>
        <h3>Оплата через терминал</h3>
        <a href="intent://scan/#Intent;scheme=zxing;package=com.google.zxing.client.android;end">Test link</a>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useOrderStore } from '../../store/order';
    import { APISettings } from '../../config/api';
    import { AppLauncher } from '@capacitor/app-launcher';

    const order = useOrderStore()

    const liqpay_link = ref('');

    const getToken = async (amount, purpose) => {
        const url = "https://dio.privatbank.ua/api/nfcpos/integrators/token.php"
        const data = {
            "operation": "pay",
            "amount": amount,
            "purpose": purpose,
        }

        const response = await fetch(url, {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
      
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
    }

    const startScan = async () => {
        document.href = "intent://scan/#Intent;scheme=zxing;package=com.google.zxing.client.android;end";
    }

    const startTerminalApp = async () => {
        var id = order.id           //зовнішній ID операції системи інтегратора
        var amount = order.sum      //сума платежу
        var purpose = "аптека"      //призначення платежу
        var operation = "purchase"  //тип операції 
        const DEEP_LINK_URL = "nfcterminal://executor"
        const app_name = "ua.privatbank.pterminal"
        
        const token = await getToken(amount, "Test");

        if (token?.success) {
            const jwtToken = token.jwt;
            const callback = "https://rro.secretclub.com.ua/api/callback"
            const url = DEEP_LINK_URL + "?jwtToken=" + token + "&callback=" + callback
            const { value } = await AppLauncher.canOpenUrl({ "url": url });

            console.log(token)
            if (value) {
                await AppLauncher.openUrl({ "url": url })
            } else {
                await AppLauncher.openUrl({ "url": "https://play.google.com/store/apps/details?id=" + app_name })
            }
        } else {
            console.log("error")
            console.log(token)
        }
                
    }

    onMounted(() => {
        startScan();
        //startTerminalApp();
    })
</script>

<style>

</style>