<template>
    <div class="card card-table table-nowrap overflow-hidden h-100" v-if="currentCheck == null">
        <div class="d-flex card-header justify-content-between">
            <h5 class="me-3 card-title mb-0">Попередні продажі</h5>
        </div>
        <div class="table-responsive">
            <table class="table table-striped mb-0" style="width:100%">
                <thead class="text-muted small">
                <tr>
                    <th class="ps-3" style="width:190px">Дата</th>
                    <th>Вартість</th>
                    <th>Кількість товарів</th>
                    <th>Фіскальний номер</th>
                </tr>
                </thead>
                <tbody class="align-middle">
                <tr v-for="check in checks.checks">
                    <template v-if="check !== null">
                        <td>
                            <div class="d-flex align-items-center">
                                <a href="#" v-on:click="setCurrentCheck(check)">{{ check.created }}</a>
                            </div>
                        </td>
                        <td>{{ check.sum }} грн.</td>
                        <td>{{ check.prods.length }}</td>
                        <td>{{ check.fn }}</td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div v-else>
        <CheckCont :check="currentCheck" :prods="currentCheck.prods" />
        <hr>
        <a href="#" class="form-control btn btn-first" v-on:click="unsetCurrentCheck()">Повернутись</a>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { APISettings } from '../config/api';
    import { useAuthStore } from '../store/auth'
    import { useChecksStore } from '../store/checks';
    import CheckCont from '../components/CheckCont.vue';

    const auth = useAuthStore()
    const checks = useChecksStore()

    const currentCheck = ref(null)

    const formatDate = computed(() => {
        return 'Yes'
    })

    onMounted(() => {

    })

    function setCurrentCheck(check) {
        currentCheck.value = check;
        currentCheck.value.prods = JSON.parse(check.prods);
    }

    function unsetCurrentCheck() {
        currentCheck.value = null;
    }
</script>

<style>

</style>
