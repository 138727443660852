import { defineStore } from "pinia";
import { ref } from "vue";
import { APISettings } from '../config/api.js';
import { useStorage } from '@vueuse/core';

export const useAuthStore = defineStore({
    id: 'auth',

    state: () => ({
        error: useStorage('auth_error', null),
        is_auth: useStorage('auth_is_auth', false),
        userid: useStorage('auth_userid', 0),
        rroid: useStorage('auth_rroid', 0),
        name: useStorage('auth_name', ''),
        token: useStorage('auth_token', '')
    }),
    
    getters: {
        isAuth: (state) => {
            return state.is_auth
        }
    },

    actions: {        
        async login(email, pass) {
            const response = await fetch(APISettings.baseURL + '/user/auth', {
                method: 'POST',
                headers: APISettings.headers,
                body: JSON.stringify({login: email, pass: pass})
            });

            const data = await response.json();
            
            if (data.error === null && data.id) {
                this.error = null;
                this.token = data.token;
                this.userid = data.id;
                this.name = data.name;
                //this.rroid = data.rro.id;
                this.is_auth = true;
                return true;
            } else {
                return false;
            }
        },

        logout() {
            this.userid = 0;
            this.rroid = 0;
            this.name = '';
            this.token = '';
            this.is_auth = false;
        }
    }
})