<template>
    <div class="col mt-3">
        <h5>Пошук товара за штрих-кодом</h5>
        <hr>
        <div class="row">
            <div class="col-12 col-sm-9 mb-3">
                <input type="text" class="form-control" id="prodcode" placeholder="1234567890" v-model="code" v-on:keypress="fetchProds()">
            </div>
            <div class="col-12 col-sm-3 mb-3">
                <button type="button" class="form-control btn btn-second" v-on:click="fetchProds()">Знайти</button>
            </div>
        </div>

        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="code === ''"></StreamBarcodeReader>
    </div>
</template>

<script setup>
    import { APISettings } from '../config/api';
    import { ref, reactive, onMounted } from 'vue';
    import { useProdsStore } from '../store/prods'
    import { StreamBarcodeReader } from "vue-barcode-reader";

    const prods = useProdsStore()

    const code = ref('')

    function fetchProds() {
        prods.fetchProdsByCode(code.value);
    }

    function onDecode(data) {
        // console.log(data);
        code.value = data;
        fetchProds();
    }

    function onLoaded(data) {
    }
</script>

<style>
</style>
