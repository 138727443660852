<template>
    <div v-if="rro.isShiftOpened">
        <div class="card">
            <div class="card-body">
                <h3>Z-Звіт</h3>
                <hr>
                <form>
                    <button type="submit" class="form-control btn btn-first mb-3" v-on:click="zReport">Z-Звіт</button>
                </form>
            </div>
        </div>
    </div>
    <div v-else>
        <OpenShiftBlock />
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';
    import OpenShiftBlock from '../components/OpenShiftBlock.vue';

    const auth = useAuthStore()
    const rro = useRroStore()

    function zReport() {
        rro.zReport();
    }

    onMounted(() => {
        console.log('ZReport mounted...')
    })
</script>

<style>

</style>
