export const APISettings = {
    token: '',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    host: 'https://rro.secretclub.com.ua',
    baseURL: 'https://rro.secretclub.com.ua/api',
    //baseURL: 'https://127.0.0.1:8000/api',    
    loginUrl: '/login',
}