<template>
    <div v-if="rro.isShiftOpened">
        <div class="card">
            <div class="card-body">
                <h3>Службове внесення</h3>
                <hr>
                <form>
                    <div class="row">
                        <div class="col-12 mb-3">
                            Сума
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control" id="inputAmount" placeholder="0.00 грн" v-model="amount">
                        </div>
                        <div class="col-6">
                            <button type="button" class="form-control btn btn-primary btn-second mb-3" v-on:click="submitForm">Внести</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div v-else>
        <OpenShiftBlock />
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';
    import { useChecksStore } from '../store/checks';
    import OpenShiftBlock from '../components/OpenShiftBlock.vue';

    const auth = useAuthStore()
    const rro = useRroStore()
    const checks = useChecksStore()

    const amount = ref(0)

    function submitForm()
    {
        if (amount.value > 0) {
            if (checks.serviceInput(amount.value)) {
                alert(amount.value + ' внесено');
                amount.value = 0;
            }
        } else {
            alert('Введіть суму');
        }
    }

    onMounted(() => {
        console.log('Input mounted...')
    })
</script>

<style>

</style>
