import { Date } from "core-js";
import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import { APISettings } from '../config/api.js';
import { useSorted, useStorage } from '@vueuse/core';
import { useAuthStore } from './auth';
import { useChecksStore } from './checks';

export const useRroStore = defineStore({
    id: 'rro',

    state: () => ({
        rro: useStorage('rro_rro', {
            id: 0,
            fn: 0,
            org: 0,
            name: '',
            addr: '',            
            token: '',
            shiftopen: false,
            offline: false,
        }),
        lastsync: useStorage('rro_lastsync', 0),
        xreport: {
            date: '',
            rro: {
                id: 0,
                fn: 0,
                name: '',
                addr: '',
            },
            real: {
                count: 0,
                amount: 0,
                taxes: 0,
                payform: {
                    cash: 0,
                    terminal1: 0,
                    terminal2: 0,
                    terminal3: 0,
                    terminal4: 0,
                },
                tax20: {
                    turnover: 0,
                    tax: 0,
                },
                tax7: {
                    turnover: 0,
                    tax: 0,
                },
                tax0: {
                    turnover: 0,
                    tax: 0,
                }
            },
            ret: {
                count: 0,
                amount: 0,
                taxes: 0,
                payform: {
                    cash: 0,
                    terminal1: 0,
                    terminal2: 0,
                    terminal3: 0,
                    terminal4: 0,
                },
                tax20: {
                    turnover: 0,
                    tax: 0,
                },
                tax7: {
                    turnover: 0,
                    tax: 0,
                },
                tax0: {
                    turnover: 0,
                    tax: 0,
                }
            }
        }
    }),

    getters: {    
        isShiftOpened: (state) => {
            return state.rro.shiftopen
        },

        isOffline: (state) => {
            return state.rro.offline
        },

        getRro: (state) => {
            return state.rro
        },
    },

    actions: {
        async sync () {
            const auth = useAuthStore();            
            //this.fetchRro(auth.rroid);
        },

        fetchRro(id) {
            if (!id) return;
            fetch(APISettings.baseURL + '/rros/' + id, {
                method: 'GET',
                headers: APISettings.headers
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.rro.id = data.id;
                this.rro.fn = data.fn;                
                this.rro.name = data.name;
                this.rro.addr = data.address;
                this.rro.token = data.token;
                this.rro.org = data.org.id;
            });
        },

        openShift() {
            const auth = useAuthStore();
            const checks = useChecksStore();
            checks.openShift();
            this.rro.shiftopen = true;
        },

        zReport() {
            const checks = useChecksStore();

            checks.zReport();
            this.rro.shiftopen = false;
        },

        xReport() {
            var now = new Date();
            this.xreport.rro.name = 'Аптека 1';
            this.xreport.rro.addr = 'Харків, 123';
            this.xreport.rro.fn = '19710571650';

            this.xreport.real.count = 10;
            this.xreport.real.taxes = 1247;
            this.xreport.real.amount = 15347;

            this.xreport.real.payform.cash = 12407;
            this.xreport.real.payform.terminal1 = 1507;
            this.xreport.real.payform.terminal2 = 0;
            this.xreport.real.payform.terminal3 = 0;
            this.xreport.real.payform.terminal4 = 0;
            this.xreport.real.tax20.turnover = 0;
            this.xreport.real.tax7.turnover = 0;
            this.xreport.real.tax0.turnover = 0;

            this.xreport.real.tax20.tax = 45;
            this.xreport.real.tax7.tax = 123;
            this.xreport.real.tax0.tax = 10;
            
            this.xreport.ret.count = 2;
            this.xreport.ret.taxes = 47;
            this.xreport.ret.amount = 347;

            this.xreport.ret.payform.cash = 107;
            this.xreport.ret.payform.terminal1 = 204;
            this.xreport.ret.payform.terminal2 = 0;
            this.xreport.ret.payform.terminal3 = 0;
            this.xreport.ret.payform.terminal4 = 0;
            this.xreport.ret.tax20.turnover = 0;
            this.xreport.ret.tax7.turnover = 0;
            this.xreport.ret.tax0.turnover = 0;

            this.xreport.ret.tax20.tax = 2;
            this.xreport.ret.tax7.tax = 10;
            this.xreport.ret.tax0.tax = 1;

            this.xreport.date = now;
            return this.xreport;
        },
    }
})