<template>
    <template v-if="$route.name!=='auth'">
        <div class="d-flex flex-column flex-root">
            <!--Page-->
            <div class="page d-flex flex-row flex-column-fluid">
                <Header />
                <main class="page-content d-flex flex-column flex-row-fluid">
                    <header class="navbar mb-3 px-3 px-lg-6 px-3 px-lg-6 align-items-center page-header navbar-expand navbar-light">
                        <a href="/" class="navbar-brand d-block d-lg-none">
                            <div class="d-flex align-items-center flex-no-wrap text-truncate">
                                <img src="/img/logo-light.png" alt="" height="30" class="logo-light">
                                <img src="/img/logo-dark.png" alt="" height="30" class="logo-dark">
                            </div>
                        </a>
                        <ul class="navbar-nav ms-auto d-flex align-items-center h-100">
                            <li class="nav-item d-flex align-items-center justify-content-center flex-column h-100 me-2">

                                <label class="dark-mode-checkbox size-40 d-flex align-items-center justify-content-center nav-link p-0" for="ChangeTheme">
                                    <input type="checkbox" id="ChangeTheme"/> <span class="slide"></span>
                                </label>
                            </li>
                            <li class="nav-item dropdown d-flex align-items-center justify-content-center flex-column h-100 rro" v-if="rro.rro.id > 0">
                                <a href="#"
                                   class="nav-link dropdown-toggle height-40 px-2 d-flex align-items-center justify-content-center"
                                   aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside">
                                    <div class="d-flex align-items-center">

                                        <!--Avatar with status-->
                                        <div class="me-sm-2 avatar xs">
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center text-white rounded-2 btn-first" v-if="rro.rro.shiftopen">
                                                check
                                            </span>
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center text-white rounded-2 btn-second" v-if="!rro.rro.shiftopen">
                                                close
                                            </span>
                                        </div>
                                        <span class="d-none d-md-inline-block">Зміна</span>
                                    </div>
                                </a>

                                <div class="dropdown-menu mt-0 p-0 dropdown-menu-end overflow-hidden" >
                                    <!--User meta-->
                                    <div class="position-relative overflow-hidden px-3 pt-4 pb-7 bg-gradient-primary text-white">
                                        <!--Divider-->
                                        <svg style="transform: rotate(-180deg)" preserveAspectRatio="none"
                                             class="position-absolute start-0 bottom-0 w-100 dropdown-wave" fill="currentColor" height="24" viewBox="0 0 1200 120"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
                                                    opacity=".25" />
                                            <path
                                                    d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
                                                    opacity=".5" />
                                            <path
                                                    d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
                                        </svg>
                                        <div class="position-relative">
                                            <h5 class="mb-1">
                                                Зміна
                                                <span v-if="rro.rro.shiftopen">відкрита</span>
                                                <span v-else>закрита</span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="pt-2">
                                        <a href="#" class="dropdown-item d-flex align-items-center" v-if="!rro.rro.shiftopen">
                                            <button type="submit" class="form-control btn btn-first" v-on:click="openShift">Відкрити зміну</button>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex align-items-center">
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center btn-first text-white rounded-2">
                                                point_of_sale
                                            </span>
                                            <span class="flex-grow-1">Каса: {{ rro.rro.name }}</span>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex align-items-center">
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center btn-first text-white rounded-2">
                                                location_on
                                            </span>
                                            <span class="flex-grow-1">Адреса: {{ rro.rro.addr }}</span>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex align-items-center">
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center btn-first text-white rounded-2">
                                                person
                                            </span>
                                            <span class="flex-grow-1">ФН: {{ rro.rro.fn }}</span>
                                        </a>
                                        <hr class="my-2">
                                        <a href="#" class="dropdown-item d-flex align-items-center" @click="logout">
                                            <span class="material-symbols-rounded align-middle me-2 size-30 fs-5 d-flex align-items-center justify-content-center text-white rounded-2 btn-second">
                                            logout
                                            </span>
                                            <span class="flex-grow-1">Вийти</span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dropdown ms-2 d-flex d-lg-none align-items-center justify-content-center flex-column h-100">
                                <a href="javascript:void(0)"
                                   class="nav-link sidebar-trigger-lg-down size-40 p-0 d-flex align-items-center justify-content-center">
                                    <span class="material-symbols-rounded align-middle">menu</span>
                                </a>
                            </li>
                        </ul>
                    </header>
                    <div class="content px-3 px-lg-6 pt-3 pb-0 d-flex flex-column-fluid position-relative">
                        <div class="container-fluid px-0">
                            <div class="row align-items-center position-relative">
                                <div class="col-sm-12 mb-3 mb-sm-0">
                                    <h3 class="mb-2">Торгівля в темряві</h3>
                                    <br>

                                    <RouterView />

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </template>
    <template v-if="$route.name=='auth'">
        <RouterView />
    </template>
</template>

<script setup>
    import {onMounted} from 'vue';
    import { RouterView, useRouter } from 'vue-router'
    import { useAuthStore } from './store/auth';
    import { useRroStore } from './store/rro';
    import Header from './components/Header.vue';
    import Footer from './components/Footer.vue';

    const auth = useAuthStore()
    const rro = useRroStore()
    const router = useRouter();

    onMounted(() => {
        if(rro.rro.org) {
            let src = '/pic/logo/'+rro.rro.org+'.jpg';
            let http = new XMLHttpRequest();
            http.open('HEAD', src, false);
            http.send();
            if (http.status != 404) {
                document.querySelectorAll(".logo-dark").forEach((item) => {
                    item.src = src;
                });
                document.querySelectorAll(".logo-light").forEach((item) => {
                    item.src = src;
                });
            }
        }
    })

    function openShift() {
        rro.openShift();
    }

    function logout() {
        auth.logout();
        router.push('/auth');
    }
</script>

<style scoped>
</style>
