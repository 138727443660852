<template>
    <div>
        <div v-if="getProds.length > 0">
            <h3 v-if="!getProd">Виберіть товар</h3>
            <h3 v-else>{{ getProd.prod.name }}</h3>
            <hr>            
            
            <Prod v-for="(prod, key) in getProds" :prod_key="key" :prod="prod" />
        </div>        
    </div>    
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useProdsStore } from '../store/prods';
    import Prod from './Prod.vue';
    import {useOrderStore} from "../store/order";

    const prods = useProdsStore();
    const order = useOrderStore();
    const prod_id = ref(0);
    const analogs = ref([]);    
    
    const getProds = computed(() => {
        return prods.prods
    })

    const getProd = computed(() => {
        return prods.prod
    })

    onMounted(() => {
    })
</script>

<style>
    
</style>
