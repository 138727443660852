<template>
    <div class="position-absolute end-0 top-0 size-40 me-2 mt-2 z-index-fixed">
        <label class="dark-mode-checkbox size-40 d-flex align-items-center justify-content-center nav-link p-0" for="ChangeTheme">
            <input type="checkbox" id="ChangeTheme"/> <span class="slide"></span>
        </label>
    </div>
    <div class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <main class="page-content overflow-hidden ms-0 d-flex flex-column flex-row-fluid">
                <div class="content p-1 d-flex flex-column-fluid position-relative">
                    <div class="container py-4">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-md-8 col-lg-5 col-xl-4">
                                <!--Logo-->
                                <a href="/" class="d-flex position-relative mb-4 z-index-1 align-items-center justify-content-center">
                                    <img src="/img/logo-light.png" alt="" height="40" class="logo-light">
                                    <img src="/img/logo-dark.png" alt="" height="40" class="logo-dark">
                                </a>
                                <!--Card-->
                                <div class="card card-body p-4">
                                    <h4 class="text-center">Вхід</h4>
                                    <p class="mb-4 text-muted text-center">
                                    </p>
                                    <div v-if="auth.error" class="alert alert-danger">{{ auth.error }}</div>
                                    <form class=" z-index-1 position-relative needs-validation" novalidate="">
                                        <div class="form-floating mb-3">
                                            <input type="email" class="form-control" required="" placeholder="name@example.com" id="floatingInput" v-model="email">
                                            <label for="floatingInput">Email</label>
                                        </div>
                                        <div class="form-floating mb-3">
                                            <input type="password" required="" class="form-control" placeholder="Password" id="floatingPassword" v-model="pass">
                                            <label for="floatingPassword">Пароль</label>
                                        </div>                                        
                                    </form>
                                    <button class="w-100 btn btn-lg btn-first" v-on:click="Login">Авторизуватися</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '../store/auth';
    import { useRroStore } from '../store/rro';
    import Footer from '../components/Footer.vue';

    const router = useRouter({
    })

    const auth = useAuthStore();
    const rro = useRroStore();

    const email = ref('')
    const pass = ref('')

    async function Login() {
        const a = await auth.login(email.value, pass.value);
        if (a) {
            router.push('/');
        }
    }
</script>

<style>
</style>
