import { defineStore } from 'pinia'
import { useAuthStore } from '@/store/auth';
import { APISettings } from '@/config/api';

export const useApiStore = defineStore({
    id: 'api',

    state: () => ({
    }),

    getters: {
        
    },

    actions: {        
        async get(url) {
            const auth = useAuthStore();
            console.log(auth.token);

            const response = await fetch(APISettings.baseURL + url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                    'cache': 'no-cache',
                },                
            });

            if (response.status == 401) {
                console.log('auth error')
                auth.logout();
            }
            
            if (response.status == 200) {
                return response.json();
            } else {
                return null;
            }
        },

        async post(url, data) {
            const auth = useAuthStore();

            try {
                const response = await fetch(APISettings.baseURL + url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.token,
                    },
                    body: JSON.stringify(data)
                });
                
                if (response.status == 401) {
                    auth.logout();
                }
    
                if (response.status == 200) {
                    return await response.json();                
                } else {
                    return null;
                }
            } catch (error) {
                console.log('api error', error);
            }
        },

        async fileUpload(url, formData) {
            const auth = useAuthStore();

            const response = await fetch(APISettings.baseURL + url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                },
                body: formData
            });
                
            if (response.status == 401) {
                auth.logout();
            }

            if (response.status == 200) {
                return await response.json();                
            } else {
                return null;
            }

            // const formData = new FormData();
            // const fileField = document.querySelector('input[type="file"]');
            
            // formData.append("username", "abc123");
            // formData.append("avatar", fileField.files[0]);
            
            // upload(formData);
        },

        async delete(url) {
            const auth = useAuthStore();
            console.log(auth.token);

            const response = await fetch(APISettings.baseURL + url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                    'cache': 'no-cache',
                },                
            });

            if (response.status == 401) {
                auth.logout();
            }
            
            if (response.status == 200) {
                return await response.json();                
            } else {
                return null;
            }
        },

    }
})