<template>
    
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useChecksStore } from '../store/checks';
    import { useProdsStore } from '../store/prods';
    import { useRroStore } from '../store/rro';
    import { useClientsStore } from '../store/clients';
    import { useRoute, useRouter } from 'vue-router';
    import { useOfflineIdsStore } from '../store/offlineids';
    import { useAuthStore } from '@/store/auth';

    const auth = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    const checks = useChecksStore()
    const prods = useProdsStore()
    const rro = useRroStore()
    const clients = useClientsStore()
    const offlineids = useOfflineIdsStore()

    onMounted(() => {        
        syncWithServer();
    })

    async function syncWithServer()
    {
        if (auth.isAuth) {
            console.log("Sync with server...");
            //!!! Не менять местами checks и prods
            const s1 = await rro.sync();
            const s2 = await checks.sync();
            const s3 = await prods.sync();
            const s4 = await clients.sync();
            const s5 = await offlineids.sync();
        }        
    }
</script>

<style>
</style>
