<template>
    <div>
        <h3>Проскануйте код</h3>                
        <QRCodeVue :text="liqpay_link" />
        <hr>
        <div class="row">
            <div class="col-12">
                <button class="form-control btn btn-first" @click="pay()">Сплачено</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useOrderStore } from '../../store/order';
    import { APISettings } from '../../config/api';
    import QRCodeVue from 'vue-qr-generator';

    const order = useOrderStore()

    const liqpay_link = ref('');

    function pay() {
        order.orderPayed();
    }
    
    onMounted(() => {
        liqpay_link.value = APISettings.host + '/pay/liqpay/' + order.id;
    })
</script>

<style>

</style>