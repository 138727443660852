import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import { APISettings } from '../config/api.js';
import { useAuthStore } from './auth';
import { useRroStore } from './rro';
import { useChecksStore } from './checks';
import { useClientsStore } from './clients';
import { useApiStore } from "./api.js";

export const useOrderStore = defineStore({
    id: 'order',

    state: () => ({
        id: useStorage('order_id', 0),
        type: useStorage('order_type', 'sale'),
        prod: useStorage('order_prod', null),
        prods: useStorage('order_prods', []),
        client: useStorage('order_client', {}),
        sum: useStorage('order_sum', 0),
        discount: useStorage('order_discount', 0),
        ready: useStorage('order_ready', false),
        check: useStorage('order_check', {
            'ready': false,
            'date': '',
            'sum': 0
        }),
        esystem: useStorage('order_esystem', null),
        show_search_block: true,
        show_payment_block: true,
        show_check_block: false,
        show_completed_block: false,
        order_completed: false,
        order_failed: false,
    }),

    getters: {
        sum: (state) => {
            var sum = 0;
            for (var i = 0; i < state.prods.length; i++) {
                sum += state.prods[i].cart_price * state.prods[i].cart_num;
            }
            
            if (state.client.discount) {
                sum = sum * (100 - state.client.discount) / 100;
            }

            if (state.discount) {
                sum = sum * (100 - state.discount) / 100;
            }

            return sum;
        },
    },

    actions: {
        fetchOrders() {

        },

        fetchClient(name) {
            const client = useClientsStore();
            this.client = client.fetchClient(name);
        },

        deleteItem(prod_key) {
            this.prods.splice(prod_key, 1);
            this.prods.length ? this.show_search_block = false : this.show_search_block = true;
        },

        beginPay(type) {
            this.esystem = type;
            this.show_check_block = true;
            this.show_payment_block = false;
            this.show_search_block = false;
        },

        orderPayed() {
            const checks = useChecksStore();            

            console.log('Check payed...');
            this.esystem = null;
            this.show_check_block = false;
            this.show_payment_block = false;
            this.show_search_block = false;
            this.show_completed_block = true;
            
            if (this.saveOrder()) {
                this.order_completed = true;
                this.order_failed = false;
            } else {
                this.order_completed = true;
                this.order_failed = true;
            }
        },
        
        saveOrder() {            
            const checks = useChecksStore();

            var prods = [];
            var sum = 0;
            var client = null;

            for (let i = 0; i < this.prods.length; i++) {
                var num = 0;
                if (this.prods[i].cart_izm == 'pieces' && this.prods[i].divider > 1) {
                    num = this.prods[i].cart_num / this.prods[i].divider;
                } else {
                    num = this.prods[i].cart_num;
                }
                sum = sum + this.prods[i].cart_price * this.prods[i].cart_num;

                let p = {
                    "id": this.prods[i].id,
                    "cart_izm": this.prods[i].cart_izm,
                    "compensation": this.prods[i].compensation,
                    "surcharge": this.prods[i].surcharge,
                    "cart_num": this.prods[i].cart_num,
                    "divider": this.prods[i].divider,
                    "num": num
                };
                prods.push(p);
            }
            console.log('Check prods', prods);

            if (this.client.id) {
                client = "/api/clients/" + this.client.id;
            }

            if (checks.sale(sum, this.esystem, prods, client)) {                
                return true;
            } else {
                return false;
            }
        },

        clearOrder() {
            this.id = 0;
            this.type = 'sale';            
            this.prod = null;
            this.prods = [];
            this.client = null;
            this.sum = 0;
            this.discount = 0;            
            this.ready = false;
            this.check = {
                'ready': false,
                'date': '',
                'sum': 0
            };
            this.show_search_block = false;
            this.show_payment_block = false;
            this.show_check_block = false;
            this.order_completed = false;
            this.order_failed = false;        
        }
    }
})