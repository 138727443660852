<template>
    <div v-if="prodChosen">
        <div class="prodvar mb-3" v-if="prods.prod.id === props.prod.id">
            <div class="row">
                <div class="col-12">
                    <div class="prodvar-name">{{ props.prod.name }}<template v-if="props.prod.form"> ({{ props.prod.form }})</template></div>
                </div>
                <div class="col-12" v-if="props.prod.prod.daysleft < 30">
                    <div class="prodvar-daysleft">Терміновий товар</div>
                </div>
                <div class="col-10">
                    <div class="prodvar-vendor-name" v-if="props.prod.vendor">Виробник: {{ props.prod.vendor.name }}</div>
                    <div class="prodvar-vendor-country" v-if="props.prod.vendor">Країна: {{ props.prod.vendor.country }}</div>
                    <div class="prodvar-code" v-if="props.prod.code">Код: {{ props.prod.code }}</div>
                    <div class="prodvar-inpack">№ в уп.: {{ props.prod.prod.inpack }}</div>
                    <div class="prodvar-num">В наявності: {{ props.prod.num }} уп.</div>
                    <div class="prodvar-inpack" v-if="props.prod.divider">Дільник: {{ props.prod.divider }} ({{ props.prod.inpack / props.prod.divider }})</div>
                    <div class="prodvar-num" v-if="props.prod.divider">В наявності: {{ props.prod.num * props.prod.divider }} од.</div>
                    <div class="prodvar-price">Ціна: {{ price }} грн.</div>
                </div>
                <div class="col-2 prodvar-more">
                    <button class="btn btn-first button-back" v-on:click="closeProd(prods.prod)"><span class="material-symbols-rounded">arrow_back</span></button>
                </div>
                <div v-if="props.prod.recipe">
                    <div><input type="checkbox" v-model="recipe"> е-Рецепт</div>
                    <div class="row" v-if="recipe">
                        <div class="col-8 mt-1">Компенсація</div>
                        <div class="col-4 mt-1"><input type="text" class="form-control text-center" maxlength="2" :value="compensation" @input="compensationEnter()" id="prodcompensation"></div>
                        <div class="col-8 mt-1">Доплата</div>
                        <div class="col-4 mt-1"><input type="text" class="form-control text-center" maxlength="2" v-model="surcharge" disabled></div>
                    </div>
                </div>
                <div class="col-3">
                    <button class="btn btn-second mt-3 button-minus" v-on:click="numMinus()">-</button>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control mt-3 text-center" @input="numEnter()" :value="num" id="prodnum">
                </div>
                <div class="col-3">
                    <button class="btn btn-second mt-3 button-plus" v-on:click="numPlus()">+</button>
                </div>
                <template v-if="props.prod.divider">
                    <div class="col-6">
                        <button class="form-control btn btn-inactive mt-3" v-on:click="changeIzm('packs')" v-bind:class="[izm=='packs' ? 'btn-second' : '']">упаковок</button>
                    </div>
                    <div class="col-6">
                        <button class="form-control btn btn-inactive mt-3" v-on:click="changeIzm('pieces')" v-bind:class="[izm=='pieces' ? 'btn-second' : '']">одиниць</button>
                    </div>
                </template>
                <div class="col-12 prodvar-tocart" v-if="!props.prod.excise">
                    <button class="form-control btn btn-first mt-3" v-on:click="addProd(props.prod)">Додати в чек</button>
                </div>
                <div class="col-12 prodvar-tocart" v-if="props.prod.excise">
                    <button class="form-control btn btn-first mt-3" v-on:click="addExcise(props.prod)">Додати в чек</button>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="prodvar">
            <div class="row">
                <div class="col-12">
                    <div class="prodvar-name">{{ props.prod.name }}<template v-if="props.prod.prod.form"> ({{ props.prod.prod.form }})</template></div>
                </div>
                <div class="col-12" v-if="props.prod.prod.daysleft < 30">
                    <div class="prodvar-daysleft">Терміновий товар</div>
                </div>
                <div class="col-6">
                    <div class="prodvar-vendor" v-if="props.prod.prod.vendor">{{ props.prod.prod.vendor.name }}, {{ props.prod.prod.vendor.country }}</div>
                    <div class="prodvar-code" v-if="props.prod.prod.code">Код: {{ props.prod.prod.code }}</div>
                </div>
                
                <div class="col-2 prodvar-more">
                    <button class="btn btn-first button-forward" v-on:click="selectProd(props.prod)"><span class="material-symbols-rounded">arrow_forward</span></button>
                </div>
                <div class="col-12">
                    <hr>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="exciseModal" tabindex="-1" role="dialog" aria-labelledby="exciseModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>Введення акцизного номера</h5>
                </div>
                <div class="modal-body">
                    <div class="col-12 mb-3">
                        <input type="text" class="form-control" placeholder="" v-model="excise">
                    </div>
                    <div class="col-12 mb-3">
                        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="excise === ''"></StreamBarcodeReader>
                    </div>
                </div>
                <div class="modal-footer" v-if="excise">
                    <button class="btn btn-first" v-on:click="addProd(props.prod)">Додати в чек</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useProdsStore } from '../store/prods';
    import { useOrderStore } from '../store/order';
    import { APISettings } from '../config/api';
    import { StreamBarcodeReader } from "vue-barcode-reader";

    const prods = useProdsStore();
    const order = useOrderStore();

    const num = ref(1);
    const izm = ref('packs');
    const price = ref(0);
    const sum = ref(0);
    const excise = ref('');
    const recipe = ref(0);
    const compensation = ref('');
    const surcharge = ref('');

    const props = defineProps({
        prod_key: Number,
        prod: Object
    });

    const getProds = computed(() => {
        return prods.prods
    })

    const prodChosen = computed(() => {
        return prods.prod
    })

    const getProd = computed(() => {
        return props.prod
    })

    const prodFound = computed(() => {
        if (!prods.prod) {
            console.log('prod not found')
            return false;
        }

        if (prods.prod.id === props.prod.id) {
            return true;
        }        
    })

    onMounted(() => {
        if (izm.value == 'packs' && props.prod.divider) {
            izm.value = 'pieces';
            price.value = props.prod.price / props.prod.divider;
        } else {
            price.value = props.prod.price;
        }
    });

    function changeIzm(value)
    {
        izm.value = value;
        if (izm.value == 'pieces') {
            price.value = props.prod.price / props.prod.divider;
        } else {
            price.value = props.prod.price;
        }
        numEnter();
    }

    function selectProd(prod) {
        prods.selectProd(prod);
    }

    function addProd(prod) {
        prod.cart_num = num.value;
        prod.cart_izm = izm.value;
        prod.cart_price = price.value;
        prod.excise = excise.value;
        if(recipe.value) {
            prod.compensation = compensation.value;
            prod.surcharge = surcharge.value;
        }
        
        prods.addProdToCheck(prod);      
        //$('#exciseModal').modal('hide');
    }

    function addExcise() {
        //$('#exciseModal').modal('show');
    }

    function onDecode(data) {
        excise.value = data;
    }

    function onLoaded(data) {
    }

    function closeProd(prod) {
        prods.prod = null;
    }

    function numPlus() {
        num.value < props.prod.num ? num.value++ : num.value = props.prod.num;
    }

    function numMinus() {
        num.value > 1 ? num.value-- : num.value = 1;
    }

    function numEnter() {
        let value = parseInt($('#prodnum').val());
        if(isNaN(value)) {
            num.value = null;
        } else {
            value > 1 ? num.value = value : num.value = 1;

            let max_num = 0;
            if (izm.value == 'packs') {
                max_num = props.prod.num;
            } else if (izm.value == 'pieces') {
                max_num = props.prod.num * props.prod.divider;
            }
            value < max_num ? num.value = value : num.value = max_num;
        }
    }

    function compensationEnter() {
        let value = parseInt($('#prodcompensation').val());
        if(isNaN(value)) {
            compensation.value = '';
        } else if(value > 0) {
            sum.value = parseInt(num.value) * parseFloat(price.value);
            compensation.value = value;
            surcharge.value = sum.value - parseInt(num.value) * compensation.value;
            // surcharge.value < 0 ? surcharge.value = sum.value : false;
        } else {
            compensation.value = '';
        }
    }

</script>

<style>
    .prodvar-name {
        font-weight: bold;
    }
    .prodvar-vendor {
        font-size: 80%;
        font-weight: normal;
    }
    .prodvar-price {
        font-weight: bold;
    }
    .prodvar-more input {
        float: right;
        font-weight: bold;
        font-size: 120%;
    }
    .prodvar-daysleft {
        color: #f00;
        font-weight: bold;
        font-size: 80%;
    }
    .button-forward, .button-back {
        float: right;
        line-height: 21px;
    }
    .button-forward .material-symbols-rounded, .button-back .material-symbols-rounded {
        font-size: 14px;
        line-height: 21px;
    }
    .button-minus {
        float: right;
    }
    .button-plus {
        float: left;
    }
</style>
