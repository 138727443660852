import { defineStore } from "pinia";
import { useStorage } from '@vueuse/core';
import { useAuthStore } from './auth';
import { useOfflineIdsStore } from './offlineids';
import { useApiStore } from "./api.js";

export const useChecksStore = defineStore('checks', {
    state: () => ({
        checks: useStorage('checks_checks', []),
        lastId: useStorage('checks_lastid', 0),
        lastSentId: useStorage('checks_lastsetid', 0),
    }),

    getters: {
        getCheck: (state) => {
            return (checkId) => state.checks.find((check) => check.id === checkId)
        },
    },

    actions: {
        async sync() {
            console.log('Checks sync...', this.checks.length, this.checks);

            for (let i = 0; i < this.checks.length; i++) {
                if (this.checks[i].sent === 1) {
                    continue;
                }
                
                this.sendCheck(i);
            }
        },

        async fetchChecks() {
            const api = useApiStore();
            const data = await api.get('/checks');

            var Checks = {};
            for (var i = 0; i < data.length; i++) {
                Checks[data[i].id] = data[i];
            }

            this.checks = Checks;
        },

        isDuplicate(data) {

        },

        nextId() {
            var nextId = this.lastId + 1;
            this.lastId = nextId;

            return nextId;
        },

        tstampToTime(tstamp) {
            const date = new Date(tstamp);
            var str = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
            return str;
        },
        
        saveCheck(type, sum, esystem, prods, discount, client) {
            const auth = useAuthStore();
            var tstamp = Math.round(Date.now() / 1000);

            const offliids = useOfflineIdsStore();
            const id = this.nextId();
            
            var check = {
                "id": id,
                "sent": 0,
                "org": auth.userid,
                "tstamp": tstamp,
                "created": this.tstampToTime(tstamp),
                "client": client,
                "discount": discount,
                "prods": prods,
                "type": this.type,
                "sum": sum,
                "fn": offliids.getId(),
                "esystem": esystem
            };

            console.log(this.checks);
            this.checks.push(check);
            this.lastId = id;
            console.log("Chesk saved: " + id);
        },

        async sendCheck(check_id) {
            const api = useApiStore();
            const auth = useAuthStore();
            const check = this.checks[check_id];

            var data = {
                "org": "/api/orgs/" + auth.userid,
                "client": null,
                "discount": check.discount.toString(),
                "prods": check.prods,
                "type": check.type,
                "sum": check.sum,
                "fn": check.fn,
                "esystem": check.esystem
            };
            console.log("Check send...", data);

            const answer = await api.post('/check/new', data);
            
            if (answer && answer.id > 0) {
                this.id = answer.id;
                this.checks[check_id].sent = 1;
            }

            return answer;
        },

        async fetchCheckProds(checkId) {
            const api = useApiStore();
            const data = await api.get('/checks/' + checkId);
            
            this.checks[checkId] = data['hydra:member'];
            console.log(this.checks[checkId]);            
        },

        serviceInput(amount) {
            this.saveCheck('input', amount, 0, [], 0, null);
            return true;
        },

        serviceOutput(amount) {
            this.saveCheck('output', amount, 0, [], 0, null);
            return true;
        },

        sale(sum, esystem, prods, client) {
            var discount = 0;
            if (client) {
                discount = client.discount;
            }

            this.saveCheck('sale', sum, esystem, prods, discount, client);
            return true;
        },

        saleReturn(sum, esystem, prods, client) {
            var discount = 0;
            if (client) {
                discount = client.discount;
            }

            this.saveCheck('return', sum, esystem, prods, discount, client);
            return true;
        },

        openShift() {
            this.saveCheck('open', 0, 0, [], 0, null);
            return true;
        },

        zReport() {
            this.saveCheck('zreport', 0, 0, [], 0, null);
            return true;
        },
    }
})