<template>
    <hr>
    <div class="prodbuy">
        <div class="row">
            <div class="col-8">
                <div class="prodvar-name">
                    {{ prod.name }}<template v-if="prod.form"> ({{ prod.form }})</template>,
                    {{ prod.cart_num }}
                    <span v-if="prod.cart_izm === 'packs'">уп.</span>
                    <span v-if="prod.cart_izm === 'pieces'">од.</span>
                </div>
            </div>
            <div class="col-4">
                <div class="prodvar-price">{{ prod.cart_price * prod.cart_num }} грн.</div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, reactive, computed, onMounted} from 'vue';
    import { useProdsStore } from '../store/prods';
    import { useOrderStore } from '../store/order';

    const prods = useProdsStore();
    const order = useOrderStore();

    const num = ref(1);
    const izm = ref('packs');
    const price = ref(0);
    const sum = ref(0);

    const props = defineProps({
        prod_key: Number,
        prod: Object        
    });

    onMounted(() => {
        console.log('CheckItem mounted...', props.prod_key);
    });

</script>

<style>
</style>
